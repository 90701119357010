import { NodeType } from "~/models/carTree";

interface TreeNode {
    id: number;
    name: string;
    selected: boolean;
    type: NodeType;
    children?: TreeNode[] | null;
    parent?: TreeNode | null;
}

export const useCarTreeStore = defineStore("car-tree-store", () => {
    const client = useSanctumClient();

    const root = ref<TreeNode>({
        id: 0,
        name: "Усі бренди",
        type: NodeType.ROOT,
        selected: false,
        parent: null,
        children: null,
    });
    const currentNode = ref<TreeNode>(root.value);
    const selectedNodes = ref<TreeNode[]>([]);

    const transformData = (data: any, type: NodeType): TreeNode => {
        return {
            id: data.id,
            name: data.name,
            type: type,
            selected: selectedNodes.value.some(node => node.id === data.id),
            children: null,
        }
    }

    const findBrand = (brandId: number): TreeNode | undefined => {
        return root.value.children?.find(brand => brand.id === brandId);
    }

    const findModel = (brandsIs: number, modelId: number): TreeNode | undefined => {
        const brand = findBrand(brandsIs);

        return brand?.children?.find(model => model.id === modelId);
    }

    const fetchBrands = async () => {
        if (currentNode.value.children) {
            return;
        }

        const response = await client("/api/cars/brands");

        const data = response.data.map((brand: any) => transformData(brand, NodeType.BRAND));

        root.value.children = data;
    }

    const fetchBrand = async (brandId: number) => {
        const response = await client(`/api/cars/brands/${brandId}`);

        const data = response.data.map((model: any) => transformData(model, NodeType.MODEL));
    }

    const fetchModel = async (brandId: number, modelId: number) => {
        const model = findModel(brandId, modelId);

        if ( ! model || model?.children) {
            return;
        }

        const response = await client(`/api/cars/brands/${brandId}/models/${modelId}`);

        const data = transformData(response.data, NodeType.GENERATION);
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useCarTreeStore, import.meta.hot)
    );
}
